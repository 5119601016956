import React from "react"
import styled from "styled-components"
import { Grid, Icon } from "semantic-ui-react"
import { Link } from "gatsby"

import loadable from '@loadable/component'

const Logo = loadable(() => import('./logomk'))

const Header = () => (
  <Grid container stackable columns={2}>
    <Grid.Column floated="left">
      <LogoContainer>
        <Link to="/">
          <Logo/>
        </Link>
      </LogoContainer>
    </Grid.Column>
    <Grid.Column floated="right">
      <ContactContainer>
        <span>
          <Icon name="mail" />
          <a href="mailto:maloobjemovekontejnery@gmail.com">
            maloobjemovekontejnery@gmail.com
          </a>
        </span>
        <br />
        <br />
        <Icon name="phone" />
        <span className="tel">
          <a href="tel:+420728241070">728 241 070</a>
        </span>
        <br />
        <br />
        <Icon name="map marker alternate" />
        <a href="#map">
          <span>Pod Kopcem 42, Teplice 41510</span>
        </a>
        <br />
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Martin Kadlec</span>
      </ContactContainer>
    </Grid.Column>
  </Grid>
)

const LogoContainer = styled.div`
  margin-top: 20px;
  @media (max-width: 767px) {
    text-align: center;
  }
`
const ContactContainer = styled.div`
  margin-top: 20px;
  font-size: 1.3em;
  font-weight: 800;
  padding: 20px;
  line-height: 23px;
  color: #001511;
  float: right;
  a {
    color: #001511;
  }
  .tel {
    font-size: 1.5em;
  }

  i {
    padding: 0px;
    float: left;
    margin-right: 10px;
    background: #93eca6;
    color: #01362b;
    border-radius: 50%;
  }

  @media (max-width: 767px) {
    float: none;
  }
`

export default Header
