/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "semantic-ui-css/semantic.min.css"
import GlobalStyle from "../styles/global"
import Header from "./header"
import { Container } from "semantic-ui-react"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"
import LogoFJ from "../images/LogoFJ.png"

const Layout = ({ children }) => {
  return (
    <BG>
      <Header />
      <Container>
        <Main>{children}</Main>
        <Footer>
          © {new Date().getFullYear()}, Martin Kadlec - Maloobjemové kontejnery
          Teplice
          <Logo>
            <a href="https://www.facebook.com/frantisekjanasgrafik/">
              <img src={LogoFJ} alt="František Janás" />
            </a>
          </Logo>
        </Footer>
        <CookieConsent
          location="bottom"
          buttonText="Rozumím"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "white", color: "black", borderTop: "1px solid #e2e2e2" }}
        >
          Tato stránka používá Cookies
        </CookieConsent>
      </Container>
      <GlobalStyle />
    </BG>
  )
}

const BG = styled.div`
  background: linear-gradient(
    140deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 35%,
    #f3f3f3 35%,
    #f3f3f3 100%
  );
  background-attachment: fixed;
`

const Main = styled.main`
  margin-top: 20px;
`

const Footer = styled.footer`
  padding: 20px;
  padding-left: 0;
  font-weight: 700;
  text-align: center;
  position: relative;
  top: 50vh;
  img {
    height: 25px;
    margin-top: 10px;
    filter: invert(9%) sepia(21%) saturate(0%) hue-rotate(188deg)
      brightness(122%) contrast(96%);
  }
`

const Logo = styled.div`
  margin-top: 5px;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
